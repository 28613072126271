<template>
  <v-data-table
    must-sort
    fixed-header
    item-key="id"
    :loading="loading"
    :headers="tableHeaders"
    :items="formularies"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [15, 25, 75, 150],
      itemsPerPageText: `${ $options.filters.capitalize($tc('formulary', 2)) } ${ $t('per page') }:`
    }"
    class="elevation-2"
  >
    <template #top>
      <v-row
        class="pt-3 px-3 mb-n3"
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col
          v-if="$auth.check({ clients: ['edit', 'view'] })"
          md="2"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
            :params="{ has: 'formulary' }"
          />
        </v-col>
        <v-col
          md="2"
        >
          <facility-filter
            key="facility-filter"
            :value.sync="options.filter.facility"
            :params="{ has: 'formulary' }"
          />
        </v-col>
        <v-col class="shrink">
          <edit-formulary
            element="btn"
            @update="fetchFormularies"
          />
        </v-col>
      </v-row>
    </template>

    <template #item.client="{ item }">
      <client-icon
        :key="item.client.uuid"
        :icon="item.client.icon"
        :name="item.client.name"
        :size="24"
      />
    </template>

    <template #item.user="{ item }">
      <template v-if="item.user">
        <v-avatar
          :key="item.user.id"
          :size="24"
          class="mr-1"
        >
          <v-img :src="item.user.avatar" />
        </v-avatar>
        {{ item.user.first_name.charAt(0) }}. {{ item.user.last_name }}
      </template>
    </template>

    <template #item.meta="{ item }">
      <template v-if="item.meta && item.meta.start_date">
        {{ item.meta.start_date | moment('MMM YYYY') }}
      </template>
    </template>

    <template #item.created_at="{ item }">
      <span class="text-caption">
        {{ item.created_at | moment('L LT') }}
      </span>
    </template>

    <template #item.status="{ item }">
      <status-chip
        :key="item.status"
        :status="(item.status != 'ready' && $auth.user().client) ? 'in progress' : (item.status == '') ? 'pending approval' : item.status"
        type="formularies"
        block
      />
    </template>

    <template #item.actions="{ item }">
      <v-menu
        bottom
        right
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            small
            ripple
            icon
            plain
            v-on="on"
          >
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list
          class="py-0"
          dense
        >
          <edit-formulary
            :formulary="item"
            @update="fetchFormularies"
          />
          <template xv-if="$auth.check({'drugs': ['*', 'delete']})">
            <v-divider />
            <v-list-item @click="deleteFormulary(item)">
              <v-list-item-action class="mr-4">
                <v-icon
                  key="fal fa-trash fa-fw"
                  small
                >
                  fal fa-trash fa-fw
                </v-icon>
              </v-list-item-action>
              <v-list-item-content><v-list-item-title>Delete</v-list-item-title></v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  components: {
    SearchBar: () => import("@/components/tables/filters/SearchBar.vue"),
    ClientFilter: () => import("@/components/tables/filters/ClientFilter.vue"),
    ClientIcon: () => import('@/components/ClientIcon.vue'),
    FacilityFilter: () => import('@/components/tables/filters/FacilityFilter.vue'),
    StatusChip: () => import('@/components/StatusChip.vue'),
    EditFormulary: () => import('@/components/drug/EditFormulary.vue'),
  },
  props: {
    search: {
      type: String,
      default: null,
    },
  },
  metaInfo: {
    title: "Formularies",
  },
  data() {
    return {
      loading: false,
      detailDialog: {},
      options: {
        search: null,
        filter: this.$route.query,
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      totalItems: null,
      formularies: [],
    };
  },
  computed: {
    tableHeaders() {
      var headers = [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Drugs",
          value: "drugs_count",
        },
        {
          text: "Clients",
          value: "clients_count",
        },
        {
          text: "Facilities",
          value: "facilities_count",
        },
        {
          sortable: false,
          align: 'right',
          value: 'actions',
        },
      ]
      if (!this.$auth.check({ clients: ["view", "edit"] })) {
        headers = headers.filter((x) => x.value && x.value != "client");
      }
      return headers;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchFormularies();
      },
    },
  },
  mounted() {
    this.$root.$on("fetch-formularies", (data) => {
      this.fetchFormularies(data);
    });
  },
  methods: {
    fetchFormularies() {
      this.loading = true;
      const promise = this.axios.get("admin/drugs/formularies", {
        params: {
          ...{
            page: this.options.page,
            count: this.options.itemsPerPage,
            sort: this.options.sortBy[0],
            order: this.options.sortDesc[0] ? "desc" : "asc",
            search: this.options.search,
          },
          ...this.$route.query,
        },
      });

      return promise
        .then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchFormularies()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.formularies = response.data.data;
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFormulary (item) {
      this.$dialog.prompt({
        icon: 'fal fa-trash',
        title: 'Delete formulary?',
        text: 'Enter the word DELETE to confirm',
      })
        .then(confirm => {
          if (confirm === 'DELETE') {
            this.axios
              .delete('admin/drugs/formularies/' + item.id)
              .then(() => {
                const index = this.formularies.indexOf(item)
                this.$delete(this.formularies, index)
                this.totalItems--
              })
          }
        })
    },
  },
};
</script>
